<template>
  <div :class="{swiperComponent:true,hideheight:!isMask}">
    <div v-if="isMask" :class="{mask:isMask}"></div>
    <div v-show="isShowPic" class="bigArea">
      <div class="bigbox">
        <el-button class="closeSwiper" type="error" icon="el-icon-close" circle @click="colseBigArea"></el-button>
        <swiper class="swiper gallery-top" :options="swiperOptionTop" ref="swiperTop">
          <swiper-slide class="bigboxslide" v-for="item,index in imglist" :key="index">
            <img class="bigimg" :index="index" :src="item.url" alt="">
            <div class="swiper-bottom">
              <div>
                <time-source :data="{ time: item.created_at, source: item.source }"></time-source>
              </div>
               <download-button 
               v-if="isshowbutton"
               :params="{
                goods_id: 2,
                condition: {
                  file_ids: item.id,
                  city_plate_id: city_plate_id
                }
              }" text="下载" size="normal"></download-button> 
            </div>
          </swiper-slide>


          <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
          <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>


        </swiper>


      </div>
    </div>
    <div class="imglistbox" v-show="listSize>0">
      <div class="thumbArea">
        <div class="leftbtn custombtn " @click="prev()">
          <img v-if="currentFristIndex > 0" class="btnarrow" src="/pic/alefts.png" alt="">
          <img v-if="currentFristIndex === 0" class="btnarrow" src="/pic/aleft.png" alt="">
        </div>

        <div class="warper">
          <div class="inner">
            <div class="imgbox" v-for="item,index in imglist" :key="index">
              <img :class="{thumbimg:true, currentSelect:currentSelectIndex === index}" :index="index" :src="item.thumb"
                alt="" @click="selectCurrent(index)">
            </div>
          </div>
        </div>
        <div class="rightbtn custombtn" @click="next()">
          <img v-if="listSize - currentLastIndex <= 1 " class="btnarrow" src="/pic/aright.png" alt="">
          <img v-if="listSize - currentLastIndex > 1 " class="btnarrow" src="/pic/arights.png" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import TimeSource from '@/components/cityreserch/TimeSource'
import { downloadZip } from '@/api/cityresearch'

import DownloadButton from "@/components/down/DownloadButton.vue"

export default {
  name: "swiper-example-thumbs-gallery",
  title: "Thumbs gallery with Two-way control",
  components: {
    Swiper,
    SwiperSlide,
    TimeSource,
    DownloadButton
  },
  props: {
    imglist: {
      type: Array,
      default: function () {

        return []

      }
    },
  },

  computed: {
    city_plate_id: {
      get() {
        return this.$store.state.city_plate_id
      },
      set(v) {
        this.$store.dispatch('changeCityPlateId', v);
      }
    }
  },

  data() {
    return {
      isshowbutton:false,
      isMask: false,
      isShowPic: false,
      swiperOptionTop: {
        loop: false,
        loopedSlides: 5, // looped slides should be the same
        // spaceBetween: 10,
        centeredSlides: true,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",

        },

        on: {
          //当改变了index
          slideChange: () => {
            const index = this.$refs.swiperTop.$swiper.activeIndex
            this.tothumbSlide(index)
          }
        }

      },

      //自定义缩略图
      thumbsize: 3,//轮播显示数量 大于thumbsize将自动轮播
      moveX: 112,//偏移量（单个缩略图包含边框的宽度）

      marginBetween: 6,//indexbox margin的像素
      currentFristIndex: 0,//当前的第一个index
      currentLastIndex: null,//当前最后一个index
      listSize: 0, //图片列表的总数量
      currentSelectIndex: null


    };
  },
  methods: {
    //改变缩略图选中的图片
    tothumbSlide(index) {
      this.currentSelectIndex = index
      if (index < this.currentFristIndex) {
        this.prev()
      }
      if (index > this.currentLastIndex) {
        this.next()
      }

    },
    //设置轮播index索引
    toSlide(index) {
      this.$refs.swiperTop.$swiper.slideTo(index, 0)
    },

    //点击下一个
    next() {
      if ((this.listSize - 1) > this.currentLastIndex) {
        let pianyiX = -(this.moveX * (this.currentFristIndex + 1))
        this.currentFristIndex = this.currentFristIndex + 1
        this.currentLastIndex = this.currentLastIndex + 1
        document.getElementsByClassName('inner')[0].style.transform = `translateX(${pianyiX}px)`;
      }

    },

    //点击上一个
    prev() {
      if ((this.currentFristIndex > 0)) {
        let pianyiX = -this.moveX * (this.currentFristIndex - 1)
        this.currentFristIndex = this.currentFristIndex - 1
        this.currentLastIndex = this.currentLastIndex - 1
        document.getElementsByClassName('inner')[0].style.transform = `translateX(${pianyiX}px)`;

      }


    },

    selectCurrent(index) {
      this.currentSelectIndex = index
      this.isShowPic = true;
      this.isMask = true;

      this.toSlide(index)

    },

    colseBigArea() {
      this.isShowPic = false;
      this.isMask = false;
    },
  },
  watch: {
    imglist: {
      handler(v) {
        if (v && v.length) {

          this.listSize = v.length

          if (this.listSize < this.thumbsize) {
            let warperwidth = this.moveX * this.listSize + (2 * this.marginBetween);
            document.getElementsByClassName('warper')[0].style.width = `${warperwidth}px`;
          } else {
            let warperwidth = this.moveX * this.thumbsize + (2 * this.marginBetween);
            document.getElementsByClassName('warper')[0].style.width = `${warperwidth}px`;
          }
        } else {
          this.listSize = 0
        }
      },
      deep: true,
      immediate: true
    }
  },
  mounted() {
    this.currentLastIndex = this.thumbsize - 1;
   
    const buttonPermission = JSON.parse(this.$store.state.user.button) //后面的参数
   
   let isper = false
    buttonPermission.map(item=>{
      if(item.name  === 'downloadIntroPic'){
        isper = true
      }
    })
    if(isper){
      this.isshowbutton = true
    }else{
      this.isshowbutton = false
    }
    // if(buttonPermission.indexOf('downloadIntroPic') > -1){
    //   this.isshowbutton = true
    // }else{
    //   this.isshowbutton = false
    // }
 
  },



};
</script>

<style lang="scss" scoped>
$imgwh: 100px;
$gap: 6px;
$thumbsize: 2;

.swiper-button-next {
  width: 40px;
  height: $imgwh;
  background: #000000;
  z-index: 20;
  opacity: 0.49;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.swiper-button-prev {
  width: 40px;
  height: $imgwh;
  background: #000000;
  z-index: 20;
  opacity: 0.49;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

}

.thumbArea {
  height: $imgwh;
  display: flex;
  justify-content: center;
  align-content: center;
}

.warper {
  height: $imgwh;
  // width: 236px;
  overflow: hidden;

  .inner {
    width: auto;
    height: $imgwh;
    display: flex;
    justify-content: flex-start;
    align-content: center;
    margin: 0 $gap;
    transition: all 0.5s ease;

    .imgbox {
      height: $imgwh;
      width: $imgwh;
      margin: 0 $gap;

      .thumbimg {
        height: $imgwh;
        width: $imgwh;
        border-radius: 4px;
        border: solid 2px #fff;
        box-sizing: border-box;
        cursor: pointer;
      }

      .currentSelect {
        border: solid 2px #28A7E1;
      }
    }

  }
}

.custombtn {
  width: 40px;
  height: $imgwh;
  background: #000000;
  z-index: 20;
  opacity: 0.49;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  .btnarrow {
    width: 30px;
    height: 40px;
  }
}

.rightbtn {
  border-radius: 0px 4px 4px 0px;
}

.leftbtn {
  border-radius: 4px 0px 0px 4px;
}


.swiper-button-disabled {
  display: none;
}

.imgareabox {
  width: 300px;

}

.swiperComponent {
  position: absolute;
  width: 100%;
  height: 100vh;
  z-index: 10;
  // background:black;
  top: 0;
  // bottom: calc(-100vh + 50px);
  bottom: 0;
  left: 0;
  right: 0;
}

.hideheight {
  // height: calc(100vh - 280px);
  height: 120px;
  z-index: 1;
  // background:black;
  top:calc(100vh - 200px);
  // bottom: calc(-100vh + 50px);
  bottom: 0;
  left: 0;
  right: 0;

}

.mask {
  // height: calc(100vh - 80px);
  // position: absolute;
  top: 0px;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  // height: calc(100vh - 45px);
  // height:100%;
  background: #000;
  position: absolute;
  opacity: 0.5;
  z-index: 2;

  // display: none;
}

.maskheight {
  height: 0px;
}

.bigArea {
  height: calc(100vh - 40px);
  width: 100%;
  // padding: 3% 10%;
  box-sizing: border-box;
  z-index: 11;
  display: flex;
  justify-content: center;
  align-items: center;
  // display: none;
}

.bigbox {
  width: 900px;
  height: 600px;
  position: relative;
  background: yellow;
}

.closeSwiper {
  z-index: 20;
  position: absolute;
  right: -14px;
  top: -14px;
  // right:50%;
  // top:50%;
}

.imglistbox {
  display: flex;
  width: 100%;
  justify-content: center;
}

.thumbArea {
  min-height: 80px;
  width: 580px;

  // position: absolute;
  z-index: 1;
  // bottom: 100px;

}

.swiper {
  .swiper-slide {
    background-size: cover;
    background-position: center;
  }

  &.gallery-top {
    height: 100%;
    width: 100%;
    z-index: 10;
    background: rgba(30, 30, 30, 0.3);
  }

  &.gallery-thumbs {
    // margin-top:20px;
    height: 80px;
    width: 380px;

    background: rgba(30, 30, 30, 0.3);
    // max-width: 968px;
    box-sizing: border-box;

    // padding-left: 106px;
    .imgbox {
      // width:100%;
      width: 434px;
    }
  }

  &.gallery-thumbs .swiper-slide {
    margin-left: 10px;
    margin-right: 10px;

    border-radius: 4px;
    opacity: 0.7;
  }

  &.gallery-thumbs .swiper-slide,
  &.gallery-thumbs .swiper-slide img {
    width: 80px;
    height: 80px;
    opacity: 1;
    border: solid 1px #fff;
    border-radius: 4px;

    box-sizing: border-box;
    margin-left: 0px;
  }

  &.gallery-thumbs .swiper-slide-active {
    opacity: 1;
  }
}



.bigboxslide {
  position: relative;

  .bigimg {
    width: 900px;
    height: 600px;
    object-fit: cover;
  }

  .swiper-bottom {
    z-index: 21;
    height: 90px;
    position: absolute;
    margin: auto;

    left: 0;
    right: 0;
    bottom: 30px;
    // background:Red;
    display: flex;
    flex-direction: column;
    justify-content: center;



    .downbtn {
      position: absolute;
      bottom: 10px;
    }
  }
}
</style>